import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMakePayment() {
  // Use toast
  const toast = useToast()

  const updateMemberInfo = (id, queryParams) => axios
    .put(`/users/${id}`, queryParams)
    .then(response => {
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating user data',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

    const inviteToFamily = queryParams => axios
    .post(`/users/${queryParams.user_id}/invite-to-family`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The invitation was successfully send',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error sending the invitation',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })
  
  return {
    updateMemberInfo,
    inviteToFamily,
  }
}

<template>
  <b-modal
    id="add-family-member"
    ref="add-family-member"
    body-class="p-10"
    centered
    title="Adding Family member"
    content-class="p-2"
    @hidden="resetModal"
    @ok="addNewMember"
  >
    <b-overlay
      id="overlay-add-family-member"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      
      <label
        class="form__row"
      >
        <span class="form__row-label">Enter email address</span>
        <input
          v-model="email"
          type="email"
          class="input"
          :class="{'is-invalid': $v.email.$dirty && (!$v.email.email || !$v.email.required) }"
          placeholder="Enter email"
        />
        <p 
          v-if="$v.email.$dirty && !$v.email.required"
          class="error"
        >
          The Email field is required
        </p>
        <p 
          v-else-if="$v.email.$dirty && !$v.email.email"
          class="error"
        >
          Enter a valid email
        </p>
      </label>

      <b-form-group label="Role">
        <v-select
          v-model="role"
          :options="rolesList"
          class="customSelect"
          :clearable="false"
          :searchable="false"
          :reduce="option => option.value"
          :disabled="user.role == 'child'"
        />
      </b-form-group>
    </b-overlay>
    <template #modal-footer="{ ok }">
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="ok()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { BModal, BButton, BOverlay, BFormGroup, } from 'bootstrap-vue'

import useFamily from '@/views/Family/useFamily'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BFormGroup,

    vSelect,
  },
  mixins: [instanceSessionCamperNames],
  data() {
    return {
      user: getUserData(),
      email: '',
      role: 'parent',
    }
  },
  validations: {
    email: { required, email },
  },
  setup() {
    const { inviteToFamily } = useFamily()
    return { inviteToFamily }
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentPortal/getIsModalLoaidng',
    }),
    rolesList() {
      return [
        {label: 'Parent', value: 'parent'},
        {label: this.camperStudent, value: 'child'},
      ]
    },
  },
  methods: {
    resetModal() {
      this.email = ''
      this.role = 'parent'
      this.$v.$reset()
    },
    async addNewMember(bvModalEvt) {
      bvModalEvt.preventDefault()
      
      if (this.$v.$invalid){
        await this.$v.$touch()
        return
      }
      
      const queryParams = {
        user_id: this.user.id,
        email: this.email,
        role: this.role,
      }
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await this.inviteToFamily(queryParams)
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
      this.$bvModal.hide('add-family-member')
    },
  },
}
</script>

<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
</style>
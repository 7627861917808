<template>
  <div>
    <div class="family site__centered">
      <h2 class="family__title">Family</h2>
      <div class="family__body">
        <SideNav />
        <div class="family__block">
          <FamilyMember 
            v-for="familyMember in familyList"
            :key="familyMember.id"
            :member="familyMember"
          />
          <button
            v-b-modal.add-family-member
            class="btn btn--color-dark family__add-member"
          >
            Add Family member
          </button> 
        </div>
      </div>
    </div>
    <AddFamilyMember />
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import SideNav from '@/components/site/SideNav.vue'
import FamilyMember from '@/views/Family/FamilyMember.vue'
import AddFamilyMember from '@/views/Family/AddFamilyMember.vue'

export default {
  name: "FamilyList",
  components: {
    FamilyMember,
    SideNav,

    AddFamilyMember,
  },
  data() {
    return {
      user: getUserData(),
    }
  },
  computed: {
    ...mapGetters({
      familyList: 'family/getFamilyList',
    }),
  },
  async created() {
    store.commit('SET_LOADING_STATUS', true)
    store.commit('family/SET_FAMILY_ID', this.user.family_id)
    await store.dispatch('family/fetchFamilyList')
    store.commit('SET_LOADING_STATUS', false)

  },
}
</script>

<style scoped>
</style>
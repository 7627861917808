const schoolType = process.env.VUE_APP_SCHOOL_TYPE

export const instanceSessionCamperNames = {
  computed: {
    sessionSemester() {
      return schoolType == 1 ? 'Semester' : 'Session'
    },
    camperStudent() {
      return schoolType == 1 ? 'Student' : 'Camper'
    },
  },
}

export const _ = null

<template>
  <div class="family-member">
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <div class="family-member__photo-block">
          <div class="family-member__avatar">
            <img
              class="family-member__with-avatar"
              v-if="member.avatar"
              :src="member.avatar" 
              alt=""
            >
            <WithoutAvatarSVG
              v-else
              class="cursor-pointer"
            />
            <div
              class="family-member__avatar_upload"
              @click="$refs.refInputEl.click()"
            >
              <UploadSVG />
            </div>
            <input 
              type="file"
              ref="refInputEl" 
              accept=".jpg, .png, .gif"
              :hidden="true"
              @input="handleFileUpload"
            >
          </div>
          <h3 class="family-member__text desktop-hidden ml-3">
            {{ member.full_name }}
            <br>
            <b-badge
              class="px-2"
              :variant="resolveBadgeVariant(member.role.name)"
            >{{ displayName(member.role.name) }}</b-badge>
          </h3> 
        </div>
      </b-col>
      <b-col
        cols="12"
        md="10"
      >
        <h3 class="family-member__text mobile-hidden">
          {{ member.full_name }}
          <b-badge
            class="ml-2 px-2"
            :variant="resolveBadgeVariant(member.role.name)"
          >{{ displayName(member.role.name) }}</b-badge>
        </h3> 
        <hr>
        <p class="family-member__details">Details</p>
        <b-row>
          <!-- <b-col
            cols="6"
            md="4"
            class="mb-4"
          >
            <div class="family-member__label">Phone</div>
            <div class="family-member__text">{{ member.phone }}</div>
          </b-col> -->
          
          <!-- <b-col
            cols="6"
            md="4"
            class="mb-4"
          >
            <div class="family-member__label">Email</div>
            <div class="family-member__text">{{ member.email }}</div>
          </b-col> -->

          <b-col
            cols="6"
            md="4"
            class="mb-4"
          >
            <div class="family-member__label">Age & Date of birth</div>
            <div class="family-member__text">
              {{ member.extra_user_info.age }}, {{ member.extra_user_info.birth_date }}
            </div>
          </b-col>

          <!-- <b-col
            cols="6"
            md="4"
            class="mb-4"
          >
            <div class="family-member__label">City</div>
            <div class="family-member__text">{{ member.address.city }}</div>
          </b-col> -->

          <!-- <b-col
            cols="6"
            md="4"
            class="mb-4"
          >
            <div class="family-member__label">State</div>
            <div class="family-member__text">{{ member.address.state }}</div>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

import { 
  BRow, BCol, BBadge,
} from 'bootstrap-vue'

import useFamily from '@/views/Family/useFamily'
import WithoutAvatarSVG from '@/assets/images/icons/without-avatar.svg'
import UploadSVG from '@/assets/images/icons/upload.svg'

export default {
  components: {
    BRow, 
    BCol,
    BBadge,

    WithoutAvatarSVG,
    UploadSVG,
  },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [instanceSessionCamperNames],
  setup() {
    const { updateMemberInfo } = useFamily()
    return { updateMemberInfo }
  },
  methods: {
    resolveBadgeVariant(role) {
      return role === 'parent' ? 'primary' : 'light-primary'
    },
    displayName(role) {
      return role === 'parent' ? 'Parent' : this.camperStudent
    },
    handleFileUpload( e ){
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.member.avatar = reader.result
          this.updateMemberAvatar()
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async updateMemberAvatar() {
      const queryParams = {
        logoUploaded: this.member.avatar
      }
      await this.updateMemberInfo(this.member.id, queryParams)
    }
  }
}
</script>

<style>

</style>